@import "../../tokens/breakpoints.scss";
@import "../../tokens/grid.scss";
@import "../../tokens/spacing.scss";

.block-intro {
  .caption {
    @include flex-basis(100%);
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: $space-64;

    @include md {
      @include flex-basis(10/12 * 100%);
      font-size: 28px;
    }

    @include lg {
      font-size: 34px;
    }
  }
}
