//-----------------------------------------------------------------
//  Spacing Units
//-----------------------------------------------------------------

$space-4: 4px;
$space-8: 8px;
$space-16: 16px;
$space-24: 24px;
$space-32: 32px;
$space-48: 48px;
$space-64: 64px;
$space-96: 96px;
