@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  overflow-x: hidden;
  margin: 0 16px; }
  @media (min-width: 750px) {
    .container {
      margin: 0 32px; } }
  @media (min-width: 1000px) {
    .container {
      margin: 0 48px; } }
  @media (min-width: 1600px) {
    .container {
      margin: 0 auto;
      max-width: 1504px; } }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .grid {
      margin: 0 -8px; } }
  @media (min-width: 1000px) {
    .grid {
      margin: 0 -12px; } }

.col {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .col {
      padding: 0 8px; } }
  @media (min-width: 1000px) {
    .col {
      padding: 0 12px; } }

.block-intro .caption {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 64px; }
  @media (min-width: 750px) {
    .block-intro .caption {
      flex-basis: 83.33333%;
      max-width: 83.33333%;
      width: 83.33333%;
      font-size: 28px; } }
  @media (min-width: 1000px) {
    .block-intro .caption {
      font-size: 34px; } }

.container {
  overflow-x: hidden;
  margin: 0 16px; }
  @media (min-width: 750px) {
    .container {
      margin: 0 32px; } }
  @media (min-width: 1000px) {
    .container {
      margin: 0 48px; } }
  @media (min-width: 1600px) {
    .container {
      margin: 0 auto;
      max-width: 1504px; } }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .grid {
      margin: 0 -8px; } }
  @media (min-width: 1000px) {
    .grid {
      margin: 0 -12px; } }

.col {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .col {
      padding: 0 8px; } }
  @media (min-width: 1000px) {
    .col {
      padding: 0 12px; } }

@-webkit-keyframes fade-scale-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0.8;
    -webkit-transform: scale(1.03);
            transform: scale(1.03); } }

@keyframes fade-scale-in {
  from {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0.8;
    -webkit-transform: scale(1.03);
            transform: scale(1.03); } }

.cover {
  position: relative;
  overflow: hidden; }
  .cover .cover-content.has-media {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    width: calc(100% - 80px);
    font-family: "Inter", sans-serif;
    color: #fff; }
  .cover .cover-content .name {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    font-size: 24px;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 32px; }
    @media (min-width: 750px) {
      .cover .cover-content .name {
        flex-basis: 83.33333%;
        max-width: 83.33333%;
        width: 83.33333%;
        font-size: 28px; } }
    @media (min-width: 1000px) {
      .cover .cover-content .name {
        font-size: 34px; } }
  .cover .cover-content .description {
    color: #fff;
    line-height: 28px;
    font-size: 19px;
    margin-bottom: 40px;
    max-width: 400px; }
  .cover .hero-attribution {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    right: 40px;
    font-size: 13px;
    line-height: 20px;
    color: #fff; }
    .cover .hero-attribution a {
      color: inherit;
      text-decoration: none;
      background-image: -webkit-gradient(linear, left top, left bottom, from(white), to(white));
      background-image: -webkit-linear-gradient(white, white);
      background-image: linear-gradient(white, white);
      background-size: 100% 1px;
      background-position: 0 100%;
      background-repeat: no-repeat;
      -webkit-transition: background-size 150ms ease;
      transition: background-size 150ms ease; }
  .cover .background {
    background-color: #2e2e2e;
    height: calc((100vw * 9) / 16);
    min-height: 360px;
    max-height: 640px;
    z-index: 1;
    position: relative; }
    .cover .background:after {
      background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 80%);
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 80%);
      content: "";
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      position: absolute;
      mix-blend-mode: multiply; }
    @media (min-width: 1100px) {
      .cover .background:after {
        background: -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 40%);
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 40%); } }
    .cover .background figure {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      background-position: center center;
      background-size: cover;
      -webkit-animation: fade-scale-in 400ms ease forwards;
              animation: fade-scale-in 400ms ease forwards;
      opacity: 0; }
      .cover .background figure.outback {
        background-image: url(/static/media/outback.9c33dd29.jpg); }

.container {
  overflow-x: hidden;
  margin: 0 16px; }
  @media (min-width: 750px) {
    .container {
      margin: 0 32px; } }
  @media (min-width: 1000px) {
    .container {
      margin: 0 48px; } }
  @media (min-width: 1600px) {
    .container {
      margin: 0 auto;
      max-width: 1504px; } }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .grid {
      margin: 0 -8px; } }
  @media (min-width: 1000px) {
    .grid {
      margin: 0 -12px; } }

.col {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .col {
      padding: 0 8px; } }
  @media (min-width: 1000px) {
    .col {
      padding: 0 12px; } }

.block-text .border {
  border-top: 1px solid #000;
  padding-top: 32px; }

.block-text .text-title {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  font-size: 22px;
  line-height: 1.4;
  font-weight: 600;
  margin: 0 0 32px; }
  @media (min-width: 750px) {
    .block-text .text-title {
      font-size: 24px; } }
  @media (min-width: 1000px) {
    .block-text .text-title {
      font-size: 28px;
      flex-basis: 33.33333%;
      max-width: 33.33333%;
      width: 33.33333%; } }

.block-text .text-content {
  font-size: 16px;
  line-height: 28px;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%; }
  @media (min-width: 750px) {
    .block-text .text-content {
      -webkit-column-count: 2;
         -moz-column-count: 2;
              column-count: 2;
      grid-column-gap: 24px;
      -webkit-column-gap: 24px;
         -moz-column-gap: 24px;
              column-gap: 24px; } }
  @media (min-width: 1000px) {
    .block-text .text-content {
      flex-basis: 66.66667%;
      max-width: 66.66667%;
      width: 66.66667%; } }
  .block-text .text-content p {
    display: inline-block; }

.block-text a {
  text-decoration: none;
  color: inherit;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#b0fbbc), to(#b0fbbc));
  background-image: -webkit-linear-gradient(#b0fbbc, #b0fbbc);
  background-image: linear-gradient(#b0fbbc, #b0fbbc);
  background-size: 100% 2px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  -webkit-transition: background-size 150ms ease;
  transition: background-size 150ms ease; }

.container {
  overflow-x: hidden;
  margin: 0 16px; }
  @media (min-width: 750px) {
    .container {
      margin: 0 32px; } }
  @media (min-width: 1000px) {
    .container {
      margin: 0 48px; } }
  @media (min-width: 1600px) {
    .container {
      margin: 0 auto;
      max-width: 1504px; } }

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .grid {
      margin: 0 -8px; } }
  @media (min-width: 1000px) {
    .grid {
      margin: 0 -12px; } }

.col {
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  padding: 0 4px;
  box-sizing: border-box; }
  @media (min-width: 750px) {
    .col {
      padding: 0 8px; } }
  @media (min-width: 1000px) {
    .col {
      padding: 0 12px; } }

.header {
  position: relative;
  z-index: 2;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%; }
  .header .header-content {
    padding: 24px 0 64px;
    display: flex; }
  .header .header-logo {
    flex-basis: 33.33333%;
    max-width: 33.33333%;
    width: 33.33333%;
    font-weight: 600; }
  .header .header-nav {
    flex-basis: 66.66667%;
    max-width: 66.66667%;
    width: 66.66667%; }
  .header .menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end; }
    .header .menu .menu-item {
      list-style: none;
      margin-left: 16px; }
      .header .menu .menu-item a {
        display: block;
        color: #000;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        outline: 0; }

.section-alert {
  background-color: #222;
  height: 4px; }

.footer {
  padding: 80px 0;
  font-size: 14px;
  line-height: 24px; }
  .footer .built-by {
    color: #888; }

p {
  margin-top: 0;
  margin-bottom: 16px; }

