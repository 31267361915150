@import "../../tokens/breakpoints.scss";
@import "../../tokens/grid.scss";
@import "../../tokens/spacing.scss";

.block-text {
  .border {
    border-top: 1px solid #000;
    padding-top: $space-32;
  }

  .text-title {
    @include flex-basis(100%);
    font-size: 22px;
    line-height: 1.4;
    font-weight: 600;
    margin: 0 0 $space-32;

    @include md {
      font-size: 24px;
    }

    @include lg {
      font-size: 28px;
      @include flex-basis(4/12 * 100%);
    }
  }

  .text-content {
    font-size: 16px;
    line-height: 28px;
    @include flex-basis(100%);

    @include md {
      column-count: 2;
      column-gap: $space-24;
    }

    @include lg {
      @include flex-basis(8/12 * 100%);
    }

    p {
      display: inline-block;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    background-image: linear-gradient(rgb(176, 251, 188), rgb(176, 251, 188));
    background-size: 100% 2px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size 150ms ease;
  }
}
