@import "./breakpoints.scss";
@import "./spacing.scss";

//-----------------------------------------------------------------
//  Container & Grid
//-----------------------------------------------------------------

$gutter: 20px;

@mixin flex-basis($value: auto) {
  flex-basis: $value;
  max-width: $value;
  width: $value;
}

.container {
  overflow-x: hidden;
  margin: 0 $space-16;

  @include md {
    margin: 0 $space-32;
  }

  @include lg {
    margin: 0 $space-48;
  }

  @include xl {
    margin: 0 auto;
    max-width: 1504px;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 ($space-8 / 2) * -1;
  box-sizing: border-box;
  // background-color: rgba(255, 0, 0, 0.1);
  // width: 100%;

  @include md {
    margin: 0 ($space-16 / 2) * -1;
  }

  @include lg {
    margin: 0 ($space-24 / 2) * -1;
  }
}

//-----------------------------------------------------------------
//  Columns
//-----------------------------------------------------------------

.col {
  @include flex-basis(100%);
  padding: 0 $space-8 / 2;
  box-sizing: border-box;

  @include md {
    padding: 0 $space-16 / 2;
  }

  @include lg {
    padding: 0 $space-24 / 2;
  }
}
