$breakpoint-sm: 550px;
$breakpoint-md: 750px;
$breakpoint-lg: 1000px;
$breakpoint-xl: 1600px;

// Small
@mixin sm() {
  @media (min-width: $breakpoint-sm) {
    @content;
  }
}

@mixin sm-only() {
  @media (max-width: $breakpoint-md - 1) {
    @content;
  }
}

// Medium
@mixin md() {
  @media (min-width: $breakpoint-md) {
    @content;
  }
}

@mixin md-and-lower() {
  @media (max-width: $breakpoint-lg - 1) {
    @content;
  }
}

@mixin md-only() {
  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1) {
    @content;
  }
}

// Large
@mixin lg() {
  @media (min-width: $breakpoint-lg) {
    @content;
  }
}

// XLarge
@mixin xl() {
  @media (min-width: $breakpoint-xl) {
    @content;
  }
}
