@import "../../tokens/breakpoints.scss";
@import "../../tokens/grid.scss";
@import "../../tokens/spacing.scss";

//  Animation

@keyframes fade-scale-in {
  from {
    opacity: 0;
    transform: scale(1);
    // transform: translate3d(0,-40px,0);
  }

  to {
    opacity: 0.8;
    transform: scale(1.03);
    // transform: translate3d(0,0,0);
  }
}

//  Start
.cover {
  position: relative;
  overflow: hidden;

  .cover-content {
    &.has-media {
      position: absolute;
      z-index: 2;
      bottom: 20px;

      width: calc(100% - 80px);
      font-family: "Inter", sans-serif;
      color: #fff;
    }

    .name {
      @include flex-basis(100%);
      font-size: 24px;
      line-height: 1.4;
      font-weight: 600;
      margin: 0 0 $space-32;

      @include md {
        @include flex-basis(10/12 * 100%);
        font-size: 28px;
      }

      @include lg {
        font-size: 34px;
      }
    }

    .description {
      color: #fff;
      line-height: 28px;
      font-size: 19px;
      margin-bottom: 40px;
      max-width: 400px;
    }
  }
  .hero-attribution {
    position: absolute;
    bottom: 20px;
    z-index: 2;
    right: 40px;
    font-size: 13px;
    line-height: 20px;
    color: #fff;

    a {
      color: inherit;
      text-decoration: none;
      background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
      background-size: 100% 1px;
      background-position: 0 100%;
      background-repeat: no-repeat;
      transition: background-size 150ms ease;
    }
  }

  .background {
    background-color: #2e2e2e;
    height: calc((100vw * 9) / 16);
    min-height: 360px;
    max-height: 640px;
    z-index: 1;
    position: relative;

    &:after {
      background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 80%);
      content: "";
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
      position: absolute;
      mix-blend-mode: multiply;
    }

    // Background gradient to sit behind text when on top of hero
    @media (min-width: 1100px) {
      &:after {
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), transparent 40%);
      }
    }

    figure {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      background-position: center center;
      background-size: cover;
      animation: fade-scale-in 400ms ease forwards;
      opacity: 0;

      &.outback {
        background-image: url("../../images/outback.jpg");
      }
    }
  }
}
