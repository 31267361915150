@import "../../tokens/breakpoints.scss";
@import "../../tokens/grid.scss";
@import "../../tokens/spacing.scss";

.header {
  position: relative;
  z-index: 2;
  @include flex-basis(100%);

  .header-content {
    padding: $space-24 0 $space-64;
    display: flex;
  }

  .header-logo {
    @include flex-basis(4/12 * 100%);
    font-weight: 600;
  }
  .header-nav {
    @include flex-basis(8/12 * 100%);
  }
  .menu {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;

    .menu-item {
      list-style: none;
      margin-left: $space-16;

      a {
        display: block;
        color: #000;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        outline: 0;
      }
    }
  }
}
